.bagBuilderSectionWrapper {
  margin-top: 40px;
  /* border-top: 1px solid #9c26b0; */
  height: auto;
  box-shadow: inset 0 0px 12px 8px #c33c4a, 0 0px 12px 8px #c33c4a;
  padding-bottom: 50px;
}

.bagBuilderSectionContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 24px;
}

.bagBuilderSectionImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.bagBuilderSectionImageTitle {
  color: white;
  font-size: 32px;
  font-weight: 800;
  margin-bottom: 16px;
  line-height: 36px;
}

.bagBuilderSectionImage {
  width: 263.925px;
  height: 571.2px;
}

.bagBuilderSectionText {
  color: white;
  font-size: 32px;
  font-weight: 800;
}

.bagBuilderSectionSpan {
  color: #c33c4a;
  font-weight: 400;
  line-height: 36px;
  font-size: 24px;
}

@media only screen and (max-width: 1150px) {
  .bagBuilderSectionContainer {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin-top: 100px;
    gap: 50px;
  }
}

@media only screen and (max-width: 650px) {
  .bagBuilderSectionContainer {
    flex-direction: column;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
}

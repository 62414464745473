.learnMoreWrapper {
  background-color: #424242;
  max-height: 750px;
  border-radius: 4px;
  overflow-y: scroll;
}

.learnMoreLogoContainer {
  width: 100%;
  background-color: white;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  justify-content: center;
}

.learnMoreLogo {
  width: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 15px;
}

.learnMoreHeadingContainer {
  padding: 15px;
  border-bottom: 1px solid grey;
}

.learnMoreHeading1 {
  padding: 0;
  margin: 0;
  font-size: 24px;
  font-weight: 800;
}

.learnMoreHeading2 {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  padding-top: 5px;
  line-height: 1;
}

.learnMoreHeading3 {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 200;
  line-height: 1;
}

.learnMoreDescriptionContainer {
  padding: 15px;
  border-bottom: 1px solid grey;
}

.learnMoreDescription {
  margin: 0px;
}

.readMoreButtonContainer {
  width: 100%;
  text-align: center;
}

.readMoreButton {
  background: none;
  border: none;
  padding: 10px;
  color: #1d73d3;
  font-size: 14px;
  margin: 0;
}

.learnMoreRatingsContainer {
  padding: 15px;
  border-bottom: 1px solid grey;
}

.learnMoreRatingsTitle {
  text-align: center;
  font-size: 16px;
}

.learnMoreRatingsItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.learnMoreRatingsItemTitle {
  font-size: 14px;
}

.learnMoreSpecsContainer {
  padding: 15px;
  border-bottom: 1px solid grey;
}

.learnMoreSpecsTitle {
  text-align: center;
  font-size: 16px;
}

.learnMoreSpecsP {
  font-size: 12px;
  font-weight: 400;
  padding: 0px;
  margin: 0px;
}

.learnMoreSpecsT {
  font-size: 14px;
  font-weight: 600;
}

.amChartContainer {
  background-color: white;
}

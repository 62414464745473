.headerWrapper {
  width: "100%";
  max-width: 1200px;
  margin: auto;
  height: 96px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerWrapper p {
  color: white;
  font-size: 18px;
}

.headerlogo {
  width: 300px;
  padding: 24px;
}

.headerbuttonWrapper {
  display: flex;
  width: 300px;
  justify-content: space-around;
}

@media only screen and (max-width: 1150px) {
  .headerWrapper {
    flex-direction: column;
  }
  .headerbuttonWrapper {
    flex-direction: column;
  }
  .headerButton {
    padding-top: 8px;
  }
}

.shareHeaderWrapper {
  width: 100%;
  background-color: #c33c4a;
  z-index: 99;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  height: 72px;
  position: fixed;
  top: 0;
}

.shareHeaderTitleContainer {
  padding-left: 16px;
}

.shareHeaderTitle {
  font-size: 24px;
  font-weight: 600;
  padding: 0px;
  margin: 0px;
}

.shareHeaderButtonsContainer {
  display: flex;
  flex-direction: row;
  margin: 0;
  justify-content: space-around;
  width: 150px;
}

.shareHeaderHome {
  text-decoration: none;
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
}

.shareButtonContainer {
  width: 75px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shareHeaderButton {
  background: none;
  border: none;
  padding: 0px;
  color: white;
  font-size: 16px;
  margin: 5px 0px 0px;
}

.mainSectionwrapper {
  width: "100%";
}

.mainSectionContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 24px;
}

.mainSectionImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.mainSectionImageTitle {
  color: white;
  font-size: 32px;
  font-weight: 800;
  margin-bottom: 16px;
  line-height: 36px;
}

.mainSectionImage {
  width: 263.925px;
  height: 571.2px;
}

#mainSectionImage1 {
  box-shadow: 0px 40px 60px 5px #9c26b0;
}
#mainSectionImage2 {
  box-shadow: 0px 40px 60px 5px #0d47a1;
}
#mainSectionImage3 {
  box-shadow: 0px 40px 60px 5px #c33c4a;
}
#mainSectionImage4 {
  box-shadow: 0px 40px 60px 5px white;
}

@media only screen and (max-width: 1150px) {
  .mainSectionContainer {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin-top: 100px;
    gap: 50px;
  }
}

@media only screen and (max-width: 650px) {
  .mainSectionContainer {
    flex-direction: column;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
}

.profileSectionWrapper {
  margin-top: 40px;
  /* border-top: 1px solid #9c26b0; */
  height: auto;
  box-shadow: inset 0 0px 12px 8px white, 0 0px 12px 8px white;
  padding-bottom: 50px;
}

.profileSectionContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 24px;
}

.profileSectionImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.profileSectionImageTitle {
  color: white;
  font-size: 32px;
  font-weight: 800;
  margin-bottom: 16px;
  line-height: 36px;
}

.profileSectionImage {
  width: 263.925px;
  height: 571.2px;
}

@media only screen and (max-width: 1150px) {
  .profileSectionContainer {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin-top: 100px;
    gap: 50px;
  }
}

@media only screen and (max-width: 650px) {
  .profileSectionContainer {
    flex-direction: column;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
}

.discsWrapper {
  height: calc(100% - 120px);
  max-width: 1400px;
  margin: 72px auto 56px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.discsContainer {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1em;
}

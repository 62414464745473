.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 98;
  overflow-y: scroll;
}

.modalContainer {
  position: relative;
  margin: 82px auto 0px;
  height: 100%;
  max-height: 750px;
  width: calc(100% - 20px);
  max-width: 450px;
  z-index: 99;
  border-radius: 4px;
}

.modalCloseButton {
  color: black;
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  padding: 8px;
  font-size: 16px;
  z-index: 99;
}

.discWrapper {
  height: 225px;
  width: 175px;
  display: relative;
  background-color: #424242;
  border-radius: 4px;
}

.logoContainer {
  height: 90px;
  width: 175px;
  background-color: white;
  padding: 5px 0px;
  display: flex;
  align-items: center;
}

.logo {
  max-height: 80px;
  max-width: 150px;
  margin: auto;
}

.discHeaderTitle {
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  padding-left: 15px;
  padding-top: 5px;
  font-weight: 500;
  margin-bottom: 0;
}

.discHeaderType {
  color: rgba(255, 255, 255, 0.9);
  font-size: 12px;
  padding-left: 15px;
  padding-top: 0;
  font-weight: 200;
  line-height: 1;
}

.buttonContainer {
  width: 100%;
  text-align: center;
}

.button {
  background: none;
  border: none;
  padding: 10px;
  color: #ffa726;
  font-size: 14px;
  margin: 0;
}

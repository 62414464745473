.ratingsWrapper {
  display: flex;
  justify-content: center;
  margin: 0px auto;
}

.rating {
  padding: 0px;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  border-left: 1px solid grey;
  width: 35px;
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
  font-size: 12px;
  margin-bottom: 0px;
}

.rating4 {
  padding: 0px;
  border: 1px solid grey;
  width: 35px;
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
  font-size: 12px;
  margin-bottom: 0px;
}

.footerWrapper {
  width: "100%";
  max-width: 1200px;
  margin: 24px auto auto;
  height: 225px;
  display: flex;
  flex-direction: column;
  color: white;
}

.footerbuttonWrapper {
  display: flex;
  width: 300px;
  justify-content: space-around;
  margin: auto;
}

.releasesLink {
  color: white;
  text-decoration: underline;
  margin-top: 16px;
}

@media only screen and (max-width: 1150px) {
  .footerWrapper {
    flex-direction: column;
  }
  .footerbuttonWrapper {
    flex-direction: column;
  }
  .footerButton {
    padding-top: 8px;
  }
}

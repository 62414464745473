.releasesWrapper {
  text-align: center;
  background-color: black;
  height: 100%;
  font-family: "Roboto Slab";
  min-height: 100vh;
  color: white;
}

@media only screen and (max-width: 1150px) {
  .releasesContainer {
    margin-top: 125px;
  }
}

.releasesTitle {
  padding-top: 16px;
  font-size: 32px;
  font-weight: 800;
  line-height: 36px;
}

.releaseWrapper {
  text-align: left;
  padding: 16px;
  max-width: 800px;
  margin: auto;
  border: 1px solid grey;
}

.releaseTitle {
  font-size: 16px;
}

.releaseTitleVal {
  font-weight: 600;
}

.releaseItemContainer {
  width: 95%;
  margin-left: 5%;
}

.releaseItemItemsContainer {
  width: 95%;
  margin-left: 5%;
}

.releaseItemTitle {
  font-size: 18px;
  font-weight: 600;
  text-decoration: underline;
}

.releaseItemItemTitle {
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 17px;
}

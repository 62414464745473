.shareBagWrapper {
  width: 100%;
  background-color: white;
  color: white;
}

.shareBagText {
  padding: 150px 0 0;
  margin: 0 auto;
  text-align: center;
  font-size: 24px;
}

.amChartContainer {
  border-radius: 4px;
}
